<template>
  <div>
    <img src="@/assets/img/invite_banner.jpg" alt="" class="header">
    <div class="public-areas public-area-shortcut margin-bottom-10" >
      <div class="weui-flex">
        <div class="weui-flex__item public-area-shortcut-item" >
          <a href="javascript:;">
            <span class="icon cash">{{ inviteeCount }}</span>邀请人数
          </a>
        </div>
        <div class="weui-flex__item public-area-shortcut-item" >
          <a href="javascript:;">
            <span class="icon cash">{{ answeredInviteeCount }}</span>成功作答好友数
          </a>
        </div>
        <div class="weui-flex__item public-area-shortcut-item" >
          <a href="javascript:;">
            <span class="icon cash">{{ invitationAwardAmount }} <i style="font-size:0.597rem;color: #e6142d;">元</i></span>
            好友帮赚
          </a>
        </div>
      </div>
    </div>
    <div class="public-areas public-area-block-doc margin-bottom-10">
      <div class="weui-cells">
        <div class="weui-cell">
          <div class="weui-cell__bd">
            <p class="public-block-title"><i class="icon public-icon icon-public-record icon-2"></i>奖励规则</p>
          </div>
        </div>
        <div class="weui-cell">
          <div class="weui-cell__bd ">
            <p class="remark">1、每成功邀请一位好友，可根据成功作答过的好友数获得相应奖励，奖励将在您的好友首次成功作答时立即到账</p>
            <p></p><table class="table table-bordered vip margin-bottom-0">
                <tbody><tr class="header">
                  <td class="text-center">奖励</td>
                  <td class="text-center">成功作答人数</td>
                </tr>
                <tr>
                  <td class="text-center">1.00元/人</td>
                  <td class="text-center">500人及以下</td>
                </tr>
                <tr>
                  <td class="text-center">1.50元/人</td>
                  <td class="text-center">501~1000人</td>
                </tr>
                <tr>
                  <td class="text-center">2.00元/人</td>
                  <td class="text-center">1001人及以上</td>
                </tr>
              </tbody></table>
                            <p></p>
          <p class="remark">个别低金额小问卷为固定奖励0.5元或以下/人</p><br>
            <p class="remark">2、好友每次成功作答（不含首次），额外奖励给您10%的提成（如：好友完成任务获得10.00元，则另外奖励给您1.00元）</p>
          </div>
        </div>
      </div>
    </div>
    <div class="public-areas public-area-block-doc margin-bottom-10">
      <div class="weui-cells">
        <div class="weui-cell">
          <div class="weui-cell__bd">
            <p class="public-block-title"><i class="icon public-icon icon-public-category-6 icon-2"></i>邀请方式</p>
          </div>
        </div>
        <div class="weui-cell">
          <div class="weui-cell__bd">
            <br>
              <div class="weui-flex">
                <div class="weui-flex__item">
                  <a href="javascript:;" @click="goPromotion" data-type="2" class="public-btn-activity public-btn-invite public-btn-invite-type">二维码邀请</a>
                </div>
                <div class="weui-flex__item">
                  <a href="javascript:;" @click="copyAndShare" data-type="3" class="public-btn-activity public-btn-invite public-btn-invite-type">链接邀请</a>
                </div>
              </div>
            <br>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import { getUserInfo, getInvitationInfo } from '../api/index'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "inviteRule",
  data () {
    return {
      inviteeCount: 0, // 被邀请人总数
      answeredInviteeCount: 0, // 答题的被邀请人总数
      invitationAwardAmount: '0.00', // 邀请奖励金额
      promotionCode: '', // 个人推广码
    }
  },
  methods: {
    // 查询邀请信息
    async doGetInvitationInfo () {
      let params = { }
      let res = {}
      try {
        res = await getInvitationInfo(params)
        if (res.code === '0000') {
          const data = res.result
          this.inviteeCount = data.inviteeCount
          this.answeredInviteeCount = data.answeredInviteeCount
          this.invitationAwardScore = data.invitationAwardScore
          this.invitationAwardAmount = data.invitationAwardAmount
        } else {
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 查询用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.promotionCode = result.promotionCode
          this.storageSet('SURVEY_mobile', result.mobile)
          this.storageSet('SURVEY_name', result.name)
          this.storageSet('SURVEY_nickName', result.nickName)
          this.storageSet('SURVEY_email', result.email)
          this.storageSet('SURVEY_alipayAcc', result.alipayAcc)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    storageSet(key, val) {
      if (!key || !val) return false
      localStorage.setItem(key, val)
    },
    // 跳转到推广码页面
    goPromotion () {
      const promotionCode = this.promotionCode
      this.$router.push({name: 'promotion', query: {promotionCode}})
    },
    // 拷贝链接并分享
    copyAndShare () {
      const origin = location.origin
      const url = `${origin}/users?inviterCode=${this.promotionCode}`
      copy(url)
      this.$toast.info('邀请链接已复制，可分享给好友')
      setTimeout(() => {
        window.location.href = 'weixin://';
      }, 1500)
    },
    doWxAuthCb () {
      this.doGetInvitationInfo()
      this.doGetUserInfo()
    }
  }
}
</script>

<style lang="less" scoped>

</style>